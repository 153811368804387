import {TagEnum} from '@shared/types/TagEnum';
import {Tag} from '@shared/types/Tag';
import {areArraysHaveSameValues} from '@shared/lib/utils';
import {ORQueryValueExpression, PlainQueryValue} from '@shared/types/Search';

export const OR_QUERY_MAP = {
  [TagEnum.person_helmet]: [TagEnum.person_helmet_vest, TagEnum.person_helmet_no_vest],
  [TagEnum.person_vest]: [TagEnum.person_helmet_vest, TagEnum.person_no_helmet_vest],
  [TagEnum.person_no_helmet]: [TagEnum.person_no_helmet_vest, TagEnum.person_no_helmet_no_vest],
  [TagEnum.person_no_vest]: [TagEnum.person_helmet_no_vest, TagEnum.person_no_helmet_no_vest],
  [TagEnum.person]: [
    TagEnum.person_helmet_no_vest,
    TagEnum.person_helmet_vest,
    TagEnum.person_no_helmet_vest,
    TagEnum.person_no_helmet_no_vest,
    TagEnum.person
  ],
  [TagEnum.person_no_helmet_no_vest]: [
    TagEnum.person_no_helmet_no_vest,
    TagEnum.person_no_helmet_vest,
    TagEnum.person_helmet_no_vest
  ]
};

function isORQuery(
  tagId: TagEnum
): tagId is
  | TagEnum.person_helmet
  | TagEnum.person_vest
  | TagEnum.person_no_helmet
  | TagEnum.person_no_vest {
  return tagId in OR_QUERY_MAP;
}

export function makeTagQueryForSearch(tag: Tag) {
  if (!isORQuery(tag.key)) {
    return {$eq: {n: tag.key}};
  }

  return {
    $or: OR_QUERY_MAP[tag.key].map(tagKey => ({$eq: {n: tagKey}}))
  };
}

function isORQueryValueExpression(
  queryValue: PlainQueryValue | ORQueryValueExpression
): queryValue is ORQueryValueExpression {
  return '$or' in queryValue;
}

export function makeSearchQueryFromRequestQuery(
  queryValue: PlainQueryValue | ORQueryValueExpression
) {
  if (isORQueryValueExpression(queryValue)) {
    for (const keyStr in OR_QUERY_MAP) {
      if (Object.hasOwn(OR_QUERY_MAP, keyStr)) {
        const key = keyStr as keyof typeof OR_QUERY_MAP;
        const ORQueryValues = OR_QUERY_MAP[key];
        const queryValueToCheck = queryValue.$or.map(({name, $eq}) => name || $eq?.n);
        if (areArraysHaveSameValues(ORQueryValues, queryValueToCheck)) {
          return key;
        }
      }
    }
  } else {
    return queryValue.name;
  }
}
