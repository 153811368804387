import {fetchAuthSession, signInWithRedirect, signOut} from 'aws-amplify/auth';
import {AuthUser} from '@shared/types/User';

export class AuthService {
  public static async currentUser(redirectTo?: string): Promise<AuthUser> {
    try {
      const {idToken} = await AuthService.currentSession(redirectTo);
      const {email, 'cognito:groups': groups, 'cognito:username': userId} = idToken.payload;

      return {email: email as string, groups: groups as string[], userId: userId as string};
    } catch (error) {
      console.log('Not signed in', {error});
      await signInWithRedirect({customState: redirectTo});
    }
  }

  public static async currentSession(redirectTo?: string) {
    try {
      const {accessToken, idToken} = (await fetchAuthSession()).tokens ?? {};

      if (!accessToken || !idToken) {
        await signInWithRedirect({customState: redirectTo});
      }

      return {idToken};
    } catch (err) {
      console.log(err);
    }
  }

  public static async logout() {
    try {
      await signOut();
    } catch (error) {
      console.log('error signing out: ', error);
    }
  }
}
