import {TagEnum} from '@shared/types/TagEnum';

export const NON_COMPLIANCE_LINE_CHART = {
  COLORS: ['#006AFF'],
  MARKER_STROKE_COLOR: '#006AFF',
  MARKER_FILL_COLOR: '#FFFFFF'
};

export const NON_COMPLIANCE_TYPES_STACK_CHART = {
  COLORS: ['#EF6C00', '#FFD740', '#BA68C8']
};

export const ATTRIBUTE_STATISTICS_PIE_CHART = {
  LABEL_COLORS: {
    [TagEnum.person_helmet_vest]: '#339DFF',
    [TagEnum.person_no_helmet_no_vest]: '#BA68C8',
    [TagEnum.person_no_helmet]: '#EF6C00',
    [TagEnum.person_no_vest]: '#FFD740'
  }
};

export const NON_COMPLIANCE_WEEKLY_STACK_CHART = {
  COLORS: {
    RELATIVE: ['#FF5722'],
    ABSOLUTE: ['#FF5722', '#339DFF']
  }
};

export const NON_COMPLIANCE_HEATMAP = {
  COLORS: ['#008FFB']
};

export const OCCUPANCY_GROSS_FLOW_PIE_CHART = {
  LABEL_COLORS: [
    '#00e4f9',
    '#00d0f9',
    '#00bbf9',
    '#00a6f9',
    '#0091f9',
    '#0068f9',
    '#0058D2',
    '#0047ab',
    '#003f97',
    '#d6bbff',
    '#b381fe',
    '#8433fe',
    '#6d0cfe',
    '#5f01ed',
    '#5501d3',
    '#4b01ba',
    '#4101a1',
    '#240058',
    '#140031'
  ]
};

export const OCCUPANCY_HOURLY_ENTRY_EXIT_CHART = {
  LABEL_COLORS: {
    ENTRY: '#339DFF',
    EXIT: '#CCCCCC',
    NET_FLOW: '#FF9800'
  }
};

export const OCCUPANCY_OVER_TIME_CHART = {
  CALIBRATION_POINT: 'rgba(230, 81, 0, 1)',
  COLORS: ['#006AFF'],
  MARKER_STROKE_COLOR: '#006AFF',
  MARKER_FILL_COLOR: '#FFFFFF'
};

export const PHASES_CHART_COLORS = [
  'rgba(102, 181, 255, 1)',
  'rgba(0, 132, 255, 1)',
  'rgba(0, 79, 153, 1)'
];
