import {Expose, Type} from 'class-transformer';
import {PhaseCount, HistoricalGraphs} from '../lib/types/PostProcessInfo';
import {
  PhaseCountModel,
  PhaseDurationModel,
  RingCountModel,
  RingDurationModel
} from './CountAndLabels.model';

export class HistoricalGraphsModel implements HistoricalGraphs {
  @Expose({name: 'phase_count'})
  @Type(() => PhaseCountModel)
  public phaseCount: PhaseCount;

  @Expose({name: 'ring_count'})
  @Type(() => RingCountModel)
  public ringCount: PhaseCount;

  @Expose({name: 'phase_duration'})
  @Type(() => PhaseDurationModel)
  public phaseDuration: PhaseCount;

  @Expose({name: 'ring_duration'})
  @Type(() => RingDurationModel)
  public ringDuration: PhaseCount;
}
