import {LanguageCode} from '@entities/LanguageSwitcher';
import {de as deLocale, enUS as enUSLocale} from 'date-fns/locale';
import {useTranslation} from 'react-i18next';

export function useDateLocale() {
  const {i18n} = useTranslation();

  const currentLanguage = i18n.language as LanguageCode;

  switch (currentLanguage) {
    case LanguageCode.EN:
      return enUSLocale;
    case LanguageCode.DE:
      return deLocale;
    default:
      return enUSLocale;
  }
}
