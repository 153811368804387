import {TextField} from '@shared/ui/TextField';
import {useEffect, useState} from 'react';
import {useCameraNameUpdate} from '../lib/hooks/useCameraNameUpdate';
import {useTranslation} from 'react-i18next';
import {Camera} from '@shared/types/Camera';
import {Tooltip} from '@shared/ui/Tooltip';
import {EditIcon} from '@shared/ui/icons';
import styles from './CameraNameUpdate.module.scss';
import {ToastService} from '@shared/lib/services';

interface Props {
  cameraId: string;
  camerasObject: {[x: string]: Camera};
}

export function CameraNameUpdate({cameraId, camerasObject}: Props) {
  const {t} = useTranslation('project');
  const [isEditing, setIsEditing] = useState(false);
  const {updateCameraName, isSaving, isSaved} = useCameraNameUpdate();
  const cameraName = camerasObject[cameraId]?.name || '';
  const [cameraNameBeingUpdated, setCameraNameBeingUpdated] = useState(cameraName);

  const errorMessage = getError();

  const handleUpdateCameraName = () => {
    if (errorMessage) return;
    if (cameraNameBeingUpdated === cameraName) {
      setIsEditing(false);
      return;
    }
    updateCameraName({cameraId, name: cameraNameBeingUpdated});
  };

  const handleKeyUp: React.EventHandler<React.KeyboardEvent<HTMLDivElement>> = event => {
    if (event.key === 'Enter') {
      handleUpdateCameraName();
    } else if (event.key === 'Escape') {
      setCameraNameBeingUpdated(cameraName);
      setIsEditing(false);
    }
  };

  useEffect(() => {
    if (!isSaving) {
      setIsEditing(false);
    }
  }, [isSaving]);

  useEffect(() => {
    if (isSaved) {
      ToastService.success(t('Camera name successfully updated'));
    }
  }, [isSaved, t]);

  if (!isEditing) {
    return (
      <div className={styles.cameraNameContainer} role="button" onClick={() => setIsEditing(true)}>
        <span className={styles.cameraName}>
          {cameraName}
          <EditIcon className={styles.icon} />
        </span>
      </div>
    );
  }

  return (
    <Tooltip open={!!errorMessage} title={errorMessage}>
      <TextField
        className={styles.nameField}
        fullWidth
        size="small"
        value={cameraNameBeingUpdated}
        onChange={event => setCameraNameBeingUpdated(event.target.value)}
        onBlur={handleUpdateCameraName}
        onKeyUp={handleKeyUp}
        disabled={isSaving}
        autoFocus
      />
    </Tooltip>
  );

  function getError() {
    const trimmedName = cameraNameBeingUpdated.trim();
    if (!trimmedName) {
      return t('Camera name could not be empty');
    }

    if (trimmedName.length > 100) {
      return t('Camera name could not be more than 100 characters');
    }

    const isDuplicateName = Object.values(camerasObject)
      .filter(camera => camera.id !== cameraId)
      .map(({name}) => name)
      .includes(trimmedName);
    if (isDuplicateName) {
      return t('Camera name already exist, please choose another name');
    }

    return undefined;
  }
}
