import {Button} from '@shared/ui/Button';
import {CloudUploadIcon} from '@shared/ui/icons';
import {useTranslation} from 'react-i18next';
import {useCameraImageIngest} from '../lib/hooks/useCameraImageIngest';
import {HiddenInput} from '@shared/ui';
import {ChangeEventHandler, useEffect} from 'react';
import {ToastService} from '@shared/lib/services';

const FILE_SIZE_LIMIT_MB = 5 * 1024 * 1024;

interface IngestImageButtonProps {
  cameraId: string;
}

export function IngestImageButton({cameraId}: IngestImageButtonProps) {
  const {t} = useTranslation('settings');

  const {ingestImage, isSaving, isSaved} = useCameraImageIngest();

  const handleUpload: ChangeEventHandler<HTMLInputElement> = event => {
    const file = event.target.files[0];
    if (file.size >= FILE_SIZE_LIMIT_MB) {
      ToastService.error(t('Upload image of size less than 5 MB'));
      event.target.value = null; // remove existing file from DOM
      return;
    }
    ingestImage({cameraId, image: file});
  };

  useEffect(() => {
    if (isSaved) {
      ToastService.success(t('Image successfully updated'));
    }
  }, [isSaved, t]);

  return (
    <Button startIcon={<CloudUploadIcon />} component={'label'} disabled={isSaving}>
      {!isSaving ? (
        <>
          {t('Ingest Image')}
          <HiddenInput onChange={handleUpload} accept="image/jpeg,image/png" type="file" />
        </>
      ) : (
        t('Uploading', {ns: 'translation'})
      )}
    </Button>
  );
}
