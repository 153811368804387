import {create} from 'zustand';
import {DateRange} from '@shared/ui/Date';
import {PresetKey} from '@shared/ui/Date';
import {devtools} from 'zustand/middleware';

export interface Store {
  dateRangePreset?: PresetKey;
  dateRange: DateRange;
}

export interface Actions {
  setDateRangePreset: (preset: PresetKey, dateRange: DateRange) => void;
  setDateRange: (dateRange: DateRange) => void;
  reset: () => void;
}

const getInitialState = (): Store => ({
  dateRangePreset: undefined,
  dateRange: undefined
});

export const useProductionTrackingFilters = create<Store & Actions>()(
  devtools(
    set => ({
      ...getInitialState(),
      setDateRange: (dateRange: DateRange) => {
        return set(() => ({
          dateRange,
          dateRangePreset: undefined
        }));
      },
      setDateRangePreset: (dateRangePreset: PresetKey, dateRange: DateRange) => {
        return set(() => ({
          dateRangePreset,
          dateRange,
          milestoneRange: {startMilestoneId: undefined, endMilestoneId: undefined}
        }));
      },
      reset: () => {
        set(getInitialState());
      }
    }),
    {name: 'production-tracking-filters'}
  )
);
