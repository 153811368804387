import {useEffect} from 'react';
import {ROUTES} from '@shared/lib/constants';
import {List} from '@shared/ui/List';
import {useTranslation} from 'react-i18next';
import {Outlet, useLocation, useNavigate} from 'react-router-dom';
import {useCurrentProject} from '@entities/project';
import {SettingsNavItem} from './SettingsNavItem';
import styles from './SettingsLayout.module.scss';

const SETTINGS_ROUTE = ROUTES.PROJECT.SETTINGS;

export function SettingsLayout() {
  const {t} = useTranslation('milestone');
  const {
    project: {id: projectId}
  } = useCurrentProject();
  const {pathname} = useLocation();
  const navigate = useNavigate();

  const isInSettingsRootPage = SETTINGS_ROUTE.ROOT.isMatch(pathname);
  const defaultSettingsPageUrl = SETTINGS_ROUTE.GENERAL.ROOT.withParams({projectId});

  useEffect(() => {
    if (isInSettingsRootPage) {
      navigate(defaultSettingsPageUrl);
    }
  }, [navigate, isInSettingsRootPage, defaultSettingsPageUrl]);

  return (
    <div className={styles.container}>
      <List className={styles.menu}>
        <SettingsNavItem
          selected={SETTINGS_ROUTE.GENERAL.ROOT.isMatch(pathname)}
          to={SETTINGS_ROUTE.GENERAL.ROOT.withParams({projectId})}
        >
          {t('General', {ns: 'translation'})}
        </SettingsNavItem>
        <SettingsNavItem
          selected={SETTINGS_ROUTE.USER.ROOT.isMatch(pathname)}
          to={SETTINGS_ROUTE.USER.ROOT.withParams({projectId})}
        >
          {t('Users', {ns: 'translation'})}
        </SettingsNavItem>
        <SettingsNavItem
          selected={SETTINGS_ROUTE.MILESTONE.ROOT.isMatch(pathname)}
          to={SETTINGS_ROUTE.MILESTONE.ROOT.withParams({projectId})}
        >
          {t('Milestones', {ns: 'milestone'})}
        </SettingsNavItem>
        <SettingsNavItem
          selected={SETTINGS_ROUTE.TAXONOMY.ROOT.isMatch(pathname)}
          to={SETTINGS_ROUTE.TAXONOMY.ROOT.withParams({projectId})}
        >
          {t('Taxonomy', {ns: 'translation'})}
        </SettingsNavItem>
        <SettingsNavItem
          selected={
            SETTINGS_ROUTE.CAMERA.ROOT.isMatch(pathname) ||
            SETTINGS_ROUTE.CAMERA.DETAIL.isMatch(pathname)
          }
          to={SETTINGS_ROUTE.CAMERA.ROOT.withParams({projectId})}
        >
          {t('Cameras', {ns: 'translation'})}
        </SettingsNavItem>
        {
          <SettingsNavItem
            selected={SETTINGS_ROUTE.PRIVACY.ROOT.isMatch(pathname)}
            to={SETTINGS_ROUTE.PRIVACY.ROOT.withParams({projectId})}
          >
            {t('Privacy', {ns: 'translation'})}
          </SettingsNavItem>
        }
      </List>
      <div className={styles.main}>
        <Outlet />
      </div>
    </div>
  );
}
