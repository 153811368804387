import {useTranslation} from 'react-i18next';
import {Typography} from '@shared/ui/Typography';
import {useCurrentProject} from '@entities/project';
import {useProjectImageStatistics} from '@entities/project';
import {GeneralSettingsPageSkeleton} from './GeneralSettingsPageSkeleton';
import {ProjectCoverImage} from '@features/projectCoverImage';
import {ProjectCoverImageUpload} from '@features/projectCoverImageUpload';
import {GeneralSettingsForm} from '@features/generalSettingsForm';
import styles from './GeneralSettingsPage.module.scss';

export function GeneralSettingsPage() {
  const {project} = useCurrentProject();
  const {data: projectImageStatistics, isFetching: isFetchingProjectImageStatistics} =
    useProjectImageStatistics();
  const {t} = useTranslation(['project']);

  if (isFetchingProjectImageStatistics) {
    return <GeneralSettingsPageSkeleton />;
  }

  return (
    <div className={styles.container}>
      <GeneralSettingsForm project={project} projectImageStatistics={projectImageStatistics} />
      <div>
        <Typography classes={styles.coverImageHeading} variant="h3">
          {t('Project Cover Image')}
        </Typography>
        <div className={styles.coverImageContainer}>
          {project.coverImage ? (
            <ProjectCoverImage project={project} />
          ) : (
            <ProjectCoverImageUpload project={project} />
          )}
        </div>
      </div>
    </div>
  );
}
