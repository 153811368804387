import {chunk, sum} from '@conxai/react-kit';
import {AxisChart, AxisChartOptions, AxisChartProps} from '../AxisChart/AxisChart';
import {addDays, getWeek, Locale} from 'date-fns';
import {DATE_FORMATS} from '@shared/lib/constants';
import {TFunction} from 'i18next';
import {useTranslation} from 'react-i18next';
import {formatInTimeZone} from 'date-fns-tz';
import {useProjectTimezone} from '@entities/project';
import {useDateLocale} from '@shared-app/lib';

export interface TimeSeriesItem {
  data: [number, number][];
  name: string;
}

export interface TimeSeriesChartProps extends AxisChartProps {
  series: TimeSeriesItem[];
  aggregation?: TimeSeriesAggregation;
  isRelative?: boolean;
}

export function TimeSeriesChart({
  series,
  aggregation,
  options,
  isRelative,
  ...restProps
}: TimeSeriesChartProps) {
  const {t} = useTranslation();
  const updatedSeries =
    aggregation === TimeSeriesAggregation.WEEKLY ? getSeriesWeekly(series, isRelative) : series;
  const timezone = useProjectTimezone();
  const locale = useDateLocale();
  return (
    <AxisChart
      {...restProps}
      series={updatedSeries}
      options={getOptions(options, aggregation, t, timezone, locale)}
    />
  );
}

function getSeriesWeekly(series: TimeSeriesItem[], isRelative?: boolean) {
  return series.map(seriesItem => {
    const chunkedData = chunk(seriesItem.data, 7);
    const aggregatedData = chunkedData.map(chunk => {
      const total = sum(chunk.map(([, value]) => value));
      return [chunk.at(0).at(0), isRelative ? total / 7 : total] as [number, number];
    });

    return {
      ...seriesItem,
      data: aggregatedData
    };
  });
}

export enum TimeSeriesAggregation {
  WEEKLY = 'weekly'
}

function getOptions(
  options: AxisChartOptions,
  aggregation: TimeSeriesAggregation,
  t: TFunction,
  timezone: string,
  locale: Locale
): AxisChartOptions {
  return {
    ...options,
    tooltip: {
      hideEmptySeries: false,
      x: {
        formatter:
          aggregation === TimeSeriesAggregation.WEEKLY
            ? val => {
                const from = new Date(val);
                const to = addDays(from, 6);
                return `${formatInTimeZone(from, timezone, DATE_FORMATS.DATE_ABBR_MONTH, {
                  locale
                })} - ${formatInTimeZone(to, timezone, DATE_FORMATS.DATE_ABBR_MONTH, {locale})}`;
              }
            : undefined
      }
    },
    xaxis: {
      tickAmount: 'dataPoints',
      labels: {
        formatter: function (timestamp: unknown) {
          // `overwriteCategories` can cause `timestamp` to be string
          if (typeof timestamp === 'string') {
            return timestamp;
          }

          // the first value is NaN for some reason
          if (Number.isNaN(timestamp) || (timestamp as number) < 0) {
            return '';
          }

          const date = new Date(timestamp as number);
          return aggregation === TimeSeriesAggregation.WEEKLY
            ? t('{{week}} CW', {week: getWeek(date)})
            : formatInTimeZone(date, timezone, DATE_FORMATS.DATE_ABBR_MONTH, {locale});
        }
      },
      ...options.xaxis
    }
  };
}
