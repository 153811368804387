import {useTranslation} from 'react-i18next';
import {PHASE_NAMES, PhaseCount} from '@modules/productionTracking/entities';
import {AxisChart, AxisChartOptions} from '@shared-app/ui';
import styles from './PhaseCountChart.module.scss';
import {differenceInMilliseconds} from 'date-fns';
import {DATE_FORMATS, ONE_DAY_IN_MS} from '@shared/lib/constants';
import {formatInTimeZone} from 'date-fns-tz';
import {PHASES_CHART_COLORS} from '@shared-app/lib';
import {TFunction} from 'i18next';

export interface PhaseCountChartProps {
  data: PhaseCount;
  title: string;
  left?: React.ReactNode;
  timezone: string;
}

export function PhaseCountChart({data, title, left, timezone}: PhaseCountChartProps) {
  const {t} = useTranslation('productionTracking');

  return (
    <div className={styles.root}>
      <div className={styles.heading}>
        <div>
          <div className={styles.title}>{title}</div>
          <div className={styles.legend}>
            {PHASE_NAMES.map((legendItem, index) => (
              <div className={styles.legendItem} key={legendItem}>
                <div
                  className={styles.marker}
                  style={{backgroundColor: PHASES_CHART_COLORS[index]}}
                ></div>
                <div>{t(legendItem)}</div>
              </div>
            ))}
          </div>
        </div>
        <div>{left}</div>
      </div>
      <AxisChart
        series={data.series.map(({name, data}) => ({name: t(name), data}))}
        options={getOptions(data, timezone, t)}
        type={'bar'}
      />
    </div>
  );
}

function getOptions(data: PhaseCount, timezone: string, t: TFunction): AxisChartOptions {
  const withinDay =
    differenceInMilliseconds(new Date(data.labels.at(-1)), new Date(data.labels.at(0))) <
    ONE_DAY_IN_MS;

  return {
    chart: {
      stacked: true,
      id: 'phase-count-chart'
    },
    colors: PHASES_CHART_COLORS,
    plotOptions: {
      bar: {
        columnWidth: '80%',
        dataLabels: {
          position: 'center',
          total: {
            style: {
              color: '#00000099',
              fontSize: '1.4rem',
              fontFamily: 'Inter',
              fontWeight: 400
            },
            enabled: true
          }
        }
      }
    },
    yaxis: {
      title: t('Phases')
    },
    xaxis: {
      title: {
        text: t('Date', {ns: 'translation'}),
        style: {
          color: '#00000099',
          fontSize: '1.4rem',
          fontFamily: 'Inter',
          fontWeight: 400
        }
      },
      categories: data.labels.map(date =>
        formatInTimeZone(date, timezone, withinDay ? DATE_FORMATS.TIME : DATE_FORMATS.DATE)
      )
    },
    dataLabels: {
      enabled: true,
      style: {
        colors: ['#ffffff'],
        fontSize: '1.2rem',
        fontFamily: 'Inter',
        fontWeight: 400
      }
    }
  };
}
