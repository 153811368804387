interface Props {
  className: string;
}

export function ProductionTrackingIcon({className}: Props) {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.000976562" width="24" height="24" rx="4" fill="#E5F3FF" />
      <mask id="mask0_13413_7021" maskUnits="userSpaceOnUse" x="0" y="0" width="25" height="24">
        <rect x="0.000976562" width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_13413_7021)">
        <path
          d="M10.451 12.975L11.751 14.275C11.9343 14.4583 12.1676 14.55 12.451 14.55C12.7343 14.55 12.9676 14.4583 13.151 14.275L16.001 11.425V12.025C16.001 12.3083 16.0968 12.5417 16.2885 12.725C16.4801 12.9083 16.7176 13 17.001 13C17.2843 13 17.5218 12.9042 17.7135 12.7125C17.9051 12.5208 18.001 12.2833 18.001 12V9C18.001 8.71667 17.9051 8.47917 17.7135 8.2875C17.5218 8.09583 17.2843 8 17.001 8H13.976C13.6926 8 13.4593 8.09583 13.276 8.2875C13.0926 8.47917 13.001 8.71667 13.001 9C13.001 9.28333 13.0968 9.52083 13.2885 9.7125C13.4801 9.90417 13.7176 10 14.001 10H14.576L12.451 12.15L11.151 10.85C10.9676 10.65 10.7343 10.55 10.451 10.55C10.1676 10.55 9.93431 10.65 9.75098 10.85L6.70098 13.9C6.50098 14.0833 6.40098 14.3167 6.40098 14.6C6.40098 14.8833 6.50098 15.1167 6.70098 15.3C6.88431 15.5 7.11764 15.6 7.40098 15.6C7.68431 15.6 7.91764 15.5 8.10098 15.3L10.451 12.975ZM5.00098 21C4.45098 21 3.98014 20.8042 3.58848 20.4125C3.19681 20.0208 3.00098 19.55 3.00098 19V5C3.00098 4.45 3.19681 3.97917 3.58848 3.5875C3.98014 3.19583 4.45098 3 5.00098 3H19.001C19.551 3 20.0218 3.19583 20.4135 3.5875C20.8051 3.97917 21.001 4.45 21.001 5V19C21.001 19.55 20.8051 20.0208 20.4135 20.4125C20.0218 20.8042 19.551 21 19.001 21H5.00098ZM5.00098 19H19.001V5H5.00098V19Z"
          fill="black"
        />
      </g>
    </svg>
  );
}
