import {LatLng} from '@shared-app/types';
import {Marker, Popup, TileLayer, useMapEvents} from 'react-leaflet';

interface LocationPickerDialogProps {
  onChange: (v: LatLng) => void;
  value: LatLng;
}

export function OpenStreetMapMarker({onChange, value}: LocationPickerDialogProps) {
  const map = useMapEvents({
    click(e) {
      onChange(e.latlng);
      map.flyTo(e.latlng, map.getZoom());
    }
  });

  return (
    <>
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <Marker position={[value.lat, value.lng]}>
        <Popup></Popup>
      </Marker>
    </>
  );
}
