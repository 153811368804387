import {MyAssistant} from './MyAssistant';
import {useCurrentProject} from '@entities/project';

export function ChatBotPage() {
  const {project} = useCurrentProject();

  return (
    <div>
      <MyAssistant project={project} />
    </div>
  );
}
