import {useEffect} from 'react';
import {sum} from '@conxai/react-kit';
import {useTranslation} from 'react-i18next';
import {useGraphs} from '../../../entities';
import {ProductionTrackingFilters} from '../../../features/productionTrackingFilters/ui/ProductionTrackingFilters';
import {useProductionTrackingFilters} from '../../../features/productionTrackingFilters/hooks/useProductionTrackingFilters';
import {PhaseCountChart} from '../../../features/phaseCountChart';
import styles from './HistoricalGraphs.module.scss';
import {DateRange} from '@shared/ui/Date';
import {PHASES_CHART_COLORS} from '@shared-app/lib';
import {useProjectTimezone} from '@entities/project';
import {RingCountChart} from '@modules/productionTracking/features/ringCountChart';
import {RingDurationChart} from '@modules/productionTracking/features/ringDurationChart';
import {PhaseDurationChart} from '@modules/productionTracking/features/phaseDurationChart';
import {HistoricalGraphs} from '@modules/productionTracking/entities/lib/types/PostProcessInfo';
import {HistoricalGraphsSkeleton} from './HistoricalGraphsSkeleton';

interface HistoricalGraphsProps {
  dateRange: DateRange;
}

export function HistoricalGraphsContainer({dateRange: allowedDateRange}: HistoricalGraphsProps) {
  const {dateRange, setDateRange} = useProductionTrackingFilters();

  useEffect(() => {
    if (!dateRange) {
      setDateRange(allowedDateRange);
    }
  }, [allowedDateRange, dateRange, setDateRange]);

  if (!dateRange) {
    return <HistoricalGraphsSkeleton />;
  }

  return (
    <div className={styles.root}>
      <ProductionTrackingFilters allowedDateRange={allowedDateRange} />
      <HistoricalGraphs dateRange={dateRange} />
    </div>
  );
}

interface HistoricalGraphsProps {
  dateRange: DateRange;
}

function HistoricalGraphs({dateRange}: HistoricalGraphsProps) {
  const {t} = useTranslation('productionTracking');
  const timezone = useProjectTimezone();
  const {data, isFetching} = useGraphs(dateRange);

  if (isFetching) {
    return <HistoricalGraphsSkeleton />;
  }

  if (!data) {
    return t('No data', {ns: 'translation'});
  }

  return (
    <div className={styles.chartContainer}>
      <RingCountChart
        data={data.ringCount}
        timezone={timezone}
        left={
          <div>
            <div>{t('Total Rings')}</div>
            <div className={styles.total}>{sum(data.ringCount.series.at(0).data)}</div>
          </div>
        }
        title={t('Ring Count')}
      />
      <RingDurationChart data={data.ringDuration} title={t('Ring Duration')} timezone={timezone} />
      <PhaseCountChart
        data={data.phaseCount}
        title={t('Phase Count')}
        timezone={timezone}
        left={
          <div>
            <div>{t('Total Phases')}</div>
            <div className={styles.total}>
              {sum(data.phaseCount.series.reduce((total, {data}) => [...total, ...data], []))}
            </div>
          </div>
        }
      />
      <PhaseDurationChart
        data={data.phaseDuration}
        title={t('Phase Duration')}
        colors={PHASES_CHART_COLORS}
        timezone={timezone}
      />
    </div>
  );
}
