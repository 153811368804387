import {PhaseCount} from '@modules/productionTracking/entities';
import {AxisChart, AxisChartOptions} from '@shared-app/ui';
import styles from './RingDurationChart.module.scss';
import {formatDurationInSecToHM} from '@shared/lib/utils';
import {TFunction} from 'i18next';
import {useTranslation} from 'react-i18next';

export interface FrequencyColumnsChartProps {
  data: PhaseCount;
  title: string;
  timezone: string;
  left?: React.ReactNode;
}

export function RingDurationChart({data, title, left}: FrequencyColumnsChartProps) {
  const {t} = useTranslation('productionTracking');
  return (
    <div className={styles.root}>
      <div className={styles.heading}>
        <div className={styles.title}>{title}</div>
        <div>{left}</div>
      </div>
      <AxisChart series={data.series} options={getOptions(data.labels, t)} type={'bar'} />
    </div>
  );
}

function getOptions(labels: string[], t: TFunction): AxisChartOptions {
  return {
    chart: {
      id: 'ring-duration-chart'
    },
    plotOptions: {
      bar: {
        columnWidth: '80%',
        dataLabels: {
          position: 'top'
        }
      }
    },
    xaxis: {
      title: {
        text: t('Rings'),
        style: {
          color: '#00000099',
          fontSize: '1.4rem',
          fontFamily: 'Inter',
          fontWeight: 400
        }
      },
      tickPlacement: 'on',
      categories: labels
    },
    yaxis: {
      title: t('Time (hh:mm)'),
      labels: {
        formatter: formatDurationInSecToHM
      }
    },
    dataLabels: {
      style: {
        colors: ['#00000099'],
        fontSize: '1.4rem',
        fontFamily: 'Inter',
        fontWeight: 400
      },
      formatter: formatDurationInSecToHM,
      enabled: true,
      offsetY: -20
    }
  };
}
