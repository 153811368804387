import {useTranslation} from 'react-i18next';
import {formatInTimeZone} from 'date-fns-tz';
import styles from './Footer.module.scss';

interface FooterProps {
  timezone: string;
}

export function Footer({timezone}: FooterProps) {
  const {t} = useTranslation('translation');
  return (
    <div className={styles.container}>
      <div>
        <a href="https://www.conxai.com/privacy-policy" target="_blank" rel="noreferrer">
          {t('Privacy Policy')}
        </a>{' '}
        •{' '}
        <a href="https://www.conxai.com/terms-of-service" target="_blank" rel="noreferrer">
          {t('Terms of Service')}
        </a>{' '}
        •{' '}
        {t('© {{year}} All rights reserved', {
          year: formatInTimeZone(new Date(), timezone, 'yyyy')
        })}
      </div>
      <img src="/assets/conxai-logo.png" />
    </div>
  );
}
