import {PHASE_NAMES, PhaseCount} from '@modules/productionTracking/entities';
import {AxisChart, AxisChartOptions} from '@shared-app/ui';
import styles from './PhaseDurationChart.module.scss';
import {formatDurationInSecToHM} from '@shared/lib/utils';
import {PHASES_CHART_COLORS} from '@shared-app/lib';
import {useTranslation} from 'react-i18next';
import {TFunction} from 'i18next';

export interface PhaseDurationProps {
  data: PhaseCount;
  title: string;
  left?: React.ReactNode;
  stacked?: boolean;
  colors?: string[];
  labelFormatter?: (v: string) => string;
  timezone: string;
}

export function PhaseDurationChart({data, title, left, colors}: PhaseDurationProps) {
  const {t} = useTranslation('productionTracking');
  return (
    <div className={styles.root}>
      <div className={styles.heading}>
        <div>
          <div className={styles.title}>{title}</div>
          <div className={styles.legend}>
            {PHASE_NAMES.map((legendItem, index) => (
              <div className={styles.legendItem} key={legendItem}>
                <div
                  className={styles.marker}
                  style={{backgroundColor: PHASES_CHART_COLORS[index]}}
                ></div>
                <div>{t(legendItem)}</div>
              </div>
            ))}
          </div>
        </div>
        <div>{left}</div>
      </div>
      <AxisChart series={data.series} options={getOptions(data, t, colors)} type={'bar'} />
    </div>
  );
}

function getOptions(data: PhaseCount, t: TFunction, colors?: string[]): AxisChartOptions {
  const optimalColumnWidthPercent = 20 + 60 / (1 + 30 * Math.exp(-data.series.length / 3));
  return {
    chart: {
      id: 'phase-duration-chart'
    },
    colors,
    plotOptions: {
      bar: {
        columnWidth: `${optimalColumnWidthPercent}%`,
        distributed: true,
        dataLabels: {
          position: 'top',
          total: {
            style: {
              color: '#00000099',
              fontSize: '1.4rem',
              fontFamily: 'Inter',
              fontWeight: 400
            },
            enabled: true
          }
        }
      }
    },
    xaxis: {
      title: {
        text: t('Phase type'),
        style: {color: '#00000099', fontSize: '1.4rem', fontFamily: 'Inter', fontWeight: 400}
      },
      labels: {
        show: false
      }
    },
    yaxis: {
      title: t('Time (hh:mm)'),
      labels: {
        formatter: formatDurationInSecToHM
      }
    },
    dataLabels: {
      enabled: true,
      formatter: formatDurationInSecToHM,
      offsetY: -10,
      style: {
        colors: ['#00000099'],
        fontSize: '1.2rem',
        fontFamily: 'Inter',
        fontWeight: 400
      }
    }
  };
}
