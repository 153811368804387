import {Logs} from '@modules/productionTracking/entities/lib/types/Logs';
import {Expose, Type} from 'class-transformer';

export class LogsModel implements Logs {
  @Expose({name: 'start'})
  @Type(() => Date)
  public startTime: Date;

  @Expose({name: 'end'})
  @Type(() => Date)
  public endTime: Date;

  @Expose({name: 'ring_id'})
  public ringId: number;

  @Expose({name: 'downtime'})
  public downtime: number;

  @Expose({name: 'gross_duration'})
  public grossDuration: number;

  @Expose({name: 'net_duration'})
  public netDuration: number;

  @Expose({name: 'phase'})
  public phase: number;
}
