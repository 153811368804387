import {productionTrackingAPI} from '@shared/api';
import {plainToInstance} from 'class-transformer';
import {WeatherForecast} from '../types/WeatherForecast';
import {WeatherForecastModel} from '../../model/WeatherForecast.model';
import {RealtimeGraphsAndTimeRangeModel} from '../../model/RingPhaseInfo.model';
import {HistoricalGraphs, RealtimeGraphsAndTimeRange} from '../types/PostProcessInfo';
import {DateRange} from '@shared/ui/Date';
import {HistoricalGraphsModel} from '../../../entities/model/HistoricalGraphs.model';
import {Logs} from '@modules/productionTracking/entities/lib/types/Logs';
import {LogsModel} from '@modules/productionTracking/entities/model/Logs.model';

export class ProductionTrackingService {
  public static async getWeatherForecast(): Promise<WeatherForecast> {
    const projects = await productionTrackingAPI.get('weather').json();

    return plainToInstance(WeatherForecastModel, projects);
  }

  public static async getRealtimeInfo(projectId: string): Promise<RealtimeGraphsAndTimeRange> {
    const data = await productionTrackingAPI.get(`${projectId}/postprocess`).json();

    return plainToInstance(RealtimeGraphsAndTimeRangeModel, data);
  }

  public static async getGraphs(
    projectId: string,
    dateRange: DateRange
  ): Promise<HistoricalGraphs> {
    const searchParams = new URLSearchParams({
      start: dateRange.startDate.toISOString(),
      end: dateRange.endDate.toISOString()
    });
    const data = await productionTrackingAPI
      .post(`${projectId}/graphs?${searchParams.toString()}`, {json: {}})
      .json();

    if (data && Object.keys(data).length === 0) {
      return null;
    }

    return plainToInstance(HistoricalGraphsModel, data);
  }

  public static async getLogs(projectId: string): Promise<Logs[]> {
    const data = (await productionTrackingAPI.get(`${projectId}/logs`).json()) as unknown[];

    return plainToInstance(LogsModel, data);
  }
}
