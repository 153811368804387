import {Typography} from '@shared/ui/Typography';
import {useTranslation} from 'react-i18next';
import {BorderButton, Button} from '@shared/ui/Button';
import {DialogContainer, DialogContent, DialogTitle, DialogCloseButton} from '@shared/ui/Dialog';
import {
  PrivacyTypeBoxBlur,
  PrivacyTypeContourBlur,
  PrivacyTypeContourFill,
  PrivacyTypeBoxFill,
  PrivacyTypeNone
} from '@shared-app/ui/icons';
import {PrivacyType} from '@shared-app/types';
import {Slider} from '@shared/ui/Slider';
import {usePrivacySettings} from '@entities/project';
import {opacityToHex} from '@shared-app/lib/utils/opacityToHex';
import {useEffect, useState} from 'react';
import {HexColorPicker} from 'react-colorful';
import {useBoolean} from '@conxai/react-kit';
import {KeyboardArrowDownIcon, SquareRoundedIcon} from '@shared/ui/icons';
import styles from './PrivacySettingsPage.module.scss';
import {PrivacyTypeOption} from './PrivacyTypeOption';
import {useUpdatePrivacySettings} from '@entities/project/lib/hooks';
import {plainToInstance} from 'class-transformer';
import {PrivacySettingsModel} from '@entities/project/model/PrivacySettings.model';
import {PrivacySettingsSkeleton} from './PrivacySettingsSkeleton';
import {ToastService} from '@shared/lib/services';
import {CircularLoader} from '@shared/ui/Loader';

export function PrivacySettingsPage() {
  const {t} = useTranslation('settings');
  const {data: privacySettings, isFetching} = usePrivacySettings();
  const {updatePrivacySettings, isSaved, isSaving} = useUpdatePrivacySettings();
  const [opacity, setOpacity] = useState(0);
  const [privacyType, setPrivacyType] = useState<PrivacyType>();
  const [color, setColor] = useState('');
  const [shouldShowColorPicker, , showColorPicker, hideColorPicker] = useBoolean();

  const handleOpacityChange = (event: Event, value: number) => {
    setOpacity(value);
  };

  const handleCancel = () => {
    setOpacity(privacySettings.opacity);
    setColor(privacySettings.color);
    setPrivacyType(privacySettings.privacyType);
  };

  const handleApply = () => {
    const settings = plainToInstance(
      PrivacySettingsModel,
      {opacity, color, privacyType},
      {ignoreDecorators: true}
    );
    settings.privacyType = privacyType;
    updatePrivacySettings(settings);
  };

  useEffect(() => {
    if (privacySettings) {
      setOpacity(privacySettings.opacity);
      setColor(privacySettings.color);
      setPrivacyType(privacySettings.privacyType);
    }
  }, [privacySettings]);

  useEffect(() => {
    if (isSaved) {
      ToastService.success(t('Privacy settings Saved successfully'));
    }
  }, [isSaved, t]);

  if (isFetching) {
    return <PrivacySettingsSkeleton />;
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Typography classes={styles.heading} variant="subtitle1">
          {t('General Privacy Settings')}
        </Typography>
        <div className={styles.actions}>
          <BorderButton size="small" onClick={handleCancel}>
            {t('Cancel')}
          </BorderButton>
          <Button
            variant="contained"
            size="small"
            onClick={handleApply}
            disabled={isSaving}
            startIcon={isSaving ? <CircularLoader size={'1em'} /> : undefined}
          >
            {t('Apply')}
          </Button>
        </div>
      </div>
      <div className={styles.options}>
        <PrivacyTypeOption
          onClick={setPrivacyType}
          privacyType={PrivacyType.CONTOUR_BLUR}
          label={t('Contour Blur')}
          selected={privacyType}
        >
          <PrivacyTypeContourBlur opacity={opacity} />
        </PrivacyTypeOption>
        <PrivacyTypeOption
          onClick={setPrivacyType}
          privacyType={PrivacyType.CONTOUR_FILL}
          label={t('Contour Fill')}
          selected={privacyType}
        >
          <PrivacyTypeContourFill color={`${color}${opacityToHex(opacity)}`} />
        </PrivacyTypeOption>
        <PrivacyTypeOption
          onClick={setPrivacyType}
          privacyType={PrivacyType.BOX_BLUR}
          label={t('Box Blur')}
          selected={privacyType}
        >
          <PrivacyTypeBoxBlur opacity={opacity} />
        </PrivacyTypeOption>
        <PrivacyTypeOption
          onClick={setPrivacyType}
          privacyType={PrivacyType.BOX_FILL}
          label={t('Box Fill')}
          selected={privacyType}
        >
          <PrivacyTypeBoxFill color={`${color}${opacityToHex(opacity)}`} />
        </PrivacyTypeOption>
        <PrivacyTypeOption onClick={setPrivacyType} label={t('None')} selected={privacyType}>
          <PrivacyTypeNone />
        </PrivacyTypeOption>
      </div>
      <div className={styles.opacityAndColor}>
        {privacyType && (
          <div className={styles.opacity}>
            <Typography variant="subtitle1">{t('Opacity')}:</Typography>
            <Slider
              step={0.1}
              min={0.1}
              max={1}
              marks
              value={opacity}
              onChange={handleOpacityChange}
            />
          </div>
        )}
        {(privacyType === PrivacyType.BOX_FILL || privacyType === PrivacyType.CONTOUR_FILL) && (
          <div className={styles.color}>
            <Typography variant="subtitle1">{t('Filling color')}:</Typography>
            <Button onClick={showColorPicker} endIcon={<KeyboardArrowDownIcon />} size="large">
              <SquareRoundedIcon className={styles.squareIcon} style={{color}} />
            </Button>
          </div>
        )}
      </div>
      <DialogContainer onClose={hideColorPicker} open={shouldShowColorPicker}>
        <DialogTitle className={styles.title} variant="h5">
          {t('Choose a color')}
          <DialogCloseButton onClick={hideColorPicker} />
        </DialogTitle>
        <DialogContent className={styles.content}>
          <HexColorPicker color={color} onChange={setColor} />
        </DialogContent>
      </DialogContainer>
    </div>
  );
}
