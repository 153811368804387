import {usePostProcessInfo} from '../../../entities';
import {PhaseCountChart} from '../../../features/phaseCountChart';
import {ProductionTrackingSkeleton} from './ProductionTrackingHomeSkeleton';
import styles from './ProductionTrackingHome.module.scss';
import {useTranslation} from 'react-i18next';
import {sum} from '@conxai/react-kit';
import {RingCountChart} from '@modules/productionTracking/features/ringCountChart';
import {useProjectTimezone} from '@entities/project';

export function ProductionTrackingHome() {
  const {data, isFetching} = usePostProcessInfo();
  const {t} = useTranslation('productionTracking');
  const timezone = useProjectTimezone();

  if (isFetching) {
    return <ProductionTrackingSkeleton />;
  }

  if (!data) {
    return 'No data';
  }

  const {charts} = data;

  return (
    <div className={styles.root}>
      <RingCountChart
        data={charts.ringCount}
        timezone={timezone}
        left={
          <div>
            <div>{t('Total Rings')}</div>
            <div className={styles.total}>{sum(charts.ringCount.series.at(0).data)}</div>
          </div>
        }
        title={t('Ring Count')}
      />
      <PhaseCountChart data={charts.phaseCount} title={t('Phase Count')} timezone={timezone} />
    </div>
  );
}
