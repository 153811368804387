import {intervalToDuration} from 'date-fns';

export function formatDurationInSec(timeInSec: number) {
  const duration = intervalToDuration({start: 0, end: timeInSec * 1000});
  const formatted = [duration.hours, duration.minutes, duration.seconds]
    .filter((value, i) => i > 0 || !!value) // return hours only if present, but return minutes and secs always
    .map(num => String(num).padStart(2, '0'))
    .join(':');

  return formatted;
}

export function formatDurationInSecToHM(timeInSec: number) {
  const duration = intervalToDuration({start: 0, end: timeInSec * 1000});
  const [, mins, hours] = [duration.hours, duration.minutes, duration.seconds]
    .filter((value, i) => i > 0 || !!value) // return hours only if present, but return minutes and secs always
    .map(String)
    .reverse();

  let formatted = `${mins}m`;

  if (hours !== undefined) {
    formatted = `${hours}h${formatted}`;
  }

  return formatted;
}
