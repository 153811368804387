import {PhaseCount} from '@modules/productionTracking/entities';
import {AxisChart, AxisChartOptions} from '@shared-app/ui';
import {differenceInMilliseconds} from 'date-fns';
import {DATE_FORMATS, ONE_DAY_IN_MS} from '@shared/lib/constants';
import styles from './RingCountChart.module.scss';
import {formatInTimeZone} from 'date-fns-tz';
import {TFunction} from 'i18next';
import {useTranslation} from 'react-i18next';

export interface FrequencyColumnsChartProps {
  data: PhaseCount;
  title: string;
  timezone: string;
  left?: React.ReactNode;
}

export function RingCountChart({data, title, left, timezone}: FrequencyColumnsChartProps) {
  const {t} = useTranslation('productionTracking');
  return (
    <div className={styles.root}>
      <div className={styles.heading}>
        <div className={styles.title}>{title}</div>
        <div>{left}</div>
      </div>
      <AxisChart series={data.series} options={getOptions(data.labels, timezone, t)} type={'bar'} />
    </div>
  );
}

function getOptions(labels: string[], timezone: string, t: TFunction): AxisChartOptions {
  const withinDay =
    differenceInMilliseconds(new Date(labels.at(-1)), new Date(labels.at(0))) < ONE_DAY_IN_MS;
  return {
    chart: {
      id: 'ring-count-chart'
    },
    states: {
      active: {
        filter: {
          type: 'none'
        }
      }
    },
    plotOptions: {
      bar: {
        columnWidth: '80%',
        dataLabels: {
          position: 'top'
        }
      }
    },
    yaxis: {
      title: t('Rings')
    },
    xaxis: {
      title: {
        text: t('Date', {ns: 'translation'}),
        style: {
          color: '#00000099',
          fontSize: '1.4rem',
          fontFamily: 'Inter',
          fontWeight: 400
        }
      },
      tickPlacement: 'on',
      categories: labels.map(date =>
        formatInTimeZone(
          new Date(date),
          timezone,
          withinDay ? DATE_FORMATS.TIME : DATE_FORMATS.DATE
        )
      )
    },
    dataLabels: {
      style: {
        colors: ['#00000099'],
        fontSize: '14px',
        fontFamily: 'Inter',
        fontWeight: 400
      },
      enabled: true,
      offsetY: -20
    }
  };
}
