import {Expose, Type} from 'class-transformer';
import {PhaseCount, RealtimeCharts, RealtimeGraphsAndTimeRange} from '../lib/types/PostProcessInfo';
import {DateRange} from '@shared/ui/Date';
import {PhaseCountModel, RingCountModel} from './CountAndLabels.model';

export class RealtimeChartsModel implements RealtimeCharts {
  @Expose({name: 'phase_count'})
  @Type(() => PhaseCountModel)
  public phaseCount: PhaseCount;

  @Expose({name: 'ring_count'})
  @Type(() => RingCountModel)
  public ringCount: PhaseCount;
}

class DateRangeModel implements DateRange {
  @Expose({name: 'start'})
  @Type(() => Date)
  public startDate?: Date;

  @Expose({name: 'end'})
  @Type(() => Date)
  public endDate?: Date;
}

export class RealtimeGraphsAndTimeRangeModel implements RealtimeGraphsAndTimeRange {
  @Expose({name: 'graph_results'})
  @Type(() => RealtimeChartsModel)
  public charts: RealtimeCharts;

  @Expose({name: 'time_range'})
  @Type(() => DateRangeModel)
  public dateRange: DateRange;
}
