import {useEffect} from 'react';
import {useQuery} from '@tanstack/react-query';
import {ToastService} from '@shared/lib/services';
import {useCurrentProject} from '@entities/project';
import {useTranslation} from 'react-i18next';
import {ProductionTrackingService} from '../services/ProductionTracking.service';
import {DateRange} from '@shared/ui/Date';

export function useGraphs(dateRange?: DateRange) {
  const {
    project: {id: projectId}
  } = useCurrentProject();
  const {t} = useTranslation('productionTracking');
  const {data, isLoading, isError, error, isPending} = useQuery({
    queryKey: ['production-tracking-graphs', projectId, dateRange],
    queryFn: () => ProductionTrackingService.getGraphs(projectId, dateRange),
    enabled: !!dateRange
  });

  const errorMessage = isError && error.message;
  useEffect(() => {
    if (errorMessage) {
      console.error(errorMessage);
      ToastService.error(t('Failed to fetch graphs information'));
    }
  }, [errorMessage, t]);

  return {
    data,
    isFetching: isLoading || isPending,
    isError
  };
}
