import {Client, ThreadState} from '@langchain/langgraph-sdk';
import {LangChainMessage} from '@assistant-ui/react-langgraph';
import {AuthService} from '@shared/lib/services';

const createClient = async () => {
  const {idToken} = await AuthService.currentSession();

  const apiUrl = process.env['PUBLIC_LANGGRAPH_API_URL'] || '/api';
  return new Client({
    apiUrl,
    defaultHeaders: {
      Authorization: `Bearer ${idToken.toString()}`
    }
  });
};

export const createThread = async () => {
  const {userId} = await AuthService.currentUser();

  const client = await createClient();
  return client.threads.create({
    metadata: {
      user_id: userId
    }
  });
};

export const getThreadState = async (
  threadId: string
): Promise<ThreadState<{messages: LangChainMessage[]}>> => {
  const client = await createClient();
  return client.threads.getState(threadId);
};

export const sendMessage = async (params: {
  threadId: string;
  messages: LangChainMessage[];
  projectId: string;
  timezone: string;
}) => {
  const {idToken} = await AuthService.currentSession();
  const client = await createClient();
  return client.runs.stream(params.threadId, process.env['PUBLIC_LANGGRAPH_ASSISTANT_ID']!, {
    input: {
      messages: params.messages
    },
    config: {
      configurable: {
        id_token: idToken.toString(),
        // We need to provide actual token id
        project_id: params.projectId,
        timezone: params.timezone
      }
    },
    streamMode: 'messages'
  });
};
