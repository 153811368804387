import {Expose, plainToClass, Transform} from 'class-transformer';
import {PhaseCount, Series} from '../lib/types/PostProcessInfo';
import {PHASE_NAMES} from '../lib/constants/phaseNames';

class SeriesModel implements Series {
  public data: number[];
  public name: string;
}

export class PhaseCountModel implements PhaseCount {
  @Expose({name: 'y_values'})
  @Transform(({value}: {value: Record<string, number[]>}) => {
    return Object.values(value).map((data, index) =>
      plainToClass(SeriesModel, {name: PHASE_NAMES[index], data})
    );
  })
  public series: Series[];

  @Expose({name: 'labels'})
  @Transform(({value}: {value: string[]}) => value.map(time => `${time}Z`))
  public labels: string[];
}

export class RingCountModel implements PhaseCount {
  @Expose({name: 'y_values'})
  @Transform(({value}: {value: number[]}) => {
    return [plainToClass(SeriesModel, {name: '', data: value})];
  })
  public series: Series[];

  @Expose({name: 'labels'})
  @Transform(({value}: {value: string[]}) => value.map(time => `${time}Z`))
  public labels: string[];
}

export class RingDurationModel implements PhaseCount {
  @Expose({name: 'y_values'})
  @Transform(({value}: {value: number[]}) => {
    return [plainToClass(SeriesModel, {name: '', data: value})];
  })
  public series: Series[];

  @Expose({name: 'labels'})
  public labels: string[];
}

export class PhaseDurationModel implements PhaseCount {
  @Expose({name: 'y_values'})
  @Transform(({value}: {value: number[]}) => {
    return [plainToClass(SeriesModel, {name: '', data: value})];
  })
  public series: Series[];

  @Expose({name: 'labels'})
  public labels: string[];
}
