import {DATE_FORMATS} from '@shared/lib/constants';
import {Typography} from '@shared/ui/Typography';
import styles from './GeneralSettingsForm.module.scss';
import {useProjectTimezone} from '@entities/project';
import {formatInTimeZone} from 'date-fns-tz';
import {TextField} from '@shared/ui/TextField';
import {BorderButton, Button} from '@shared/ui/Button';
import {CircularLoader} from '@shared/ui/Loader';
import {useSaveProjectInfo} from '@entities/project/lib/hooks';
import {Formik} from 'formik';
import {useBoolean} from '@conxai/react-kit';
import classNames from 'classnames';
import {LocationPickerDialog, TimeZoneSelect} from '@shared-app/ui';
import {useEffect, useMemo} from 'react';
import {ToastService} from '@shared/lib/services';
import {Project} from '@shared/types/Project';
import {useTranslation} from 'react-i18next';
import {ProjectImageStatistics} from '@shared/types/ProjectImageStatistics';
import {DialogContainer, DialogContent} from '@shared/ui/Dialog';
import {DateCalendar} from '@shared/ui/Date';
import {AddLocationIcon} from '@shared/ui/icons';
import {roundNumber} from '@shared/lib/utils';
import {LatLng} from '@shared-app/types';
import {TFunction} from 'i18next';

interface GeneralSettingsFormProps {
  project: Project;
  projectImageStatistics: ProjectImageStatistics;
}

interface FormValues {
  name: string;
  locationName: string;
  timezone: string;
  startedOn: Date;
  location: LatLng;
}

interface FormErrors extends Partial<Record<keyof FormValues, string>> {}

export function GeneralSettingsForm({project, projectImageStatistics}: GeneralSettingsFormProps) {
  const timezone = useProjectTimezone();
  const [isEditing, , onEdit, onEditCancel] = useBoolean(false);
  const {isSaved, isSaving, updateInfo} = useSaveProjectInfo(project);
  const [isPickerOpen, , showPicker, hidePicker] = useBoolean(false);
  const [isMapPickerOpen, , showMapPicker, hideMapPicker] = useBoolean(false);
  const {t} = useTranslation(['project', 'settings']);

  const defaultCenterLocation = useMemo(
    () => ({
      lat: project.settings.location.lat || 41.015137,
      lng: project.settings.location.long || 28.97953
    }),
    [project.settings.location]
  );

  const handleSave = (formValues: FormValues) => {
    updateInfo(formValues);
  };

  const defaultFormValues = useMemo(
    () => ({
      name: project.name,
      locationName: project.settings.location.name,
      location: defaultCenterLocation,
      startedOn: project.settings.startedOn,
      timezone
    }),
    [
      project.name,
      project.settings.location.name,
      project.settings.startedOn,
      defaultCenterLocation,
      timezone
    ]
  );

  useEffect(() => {
    if (isSaved) {
      ToastService.success(t('General settings saved successfully'));
      onEditCancel();
    }
  }, [isSaved, onEditCancel, t]);

  return (
    <Formik
      validate={getValidate(t)}
      initialValues={defaultFormValues}
      onSubmit={handleSave}
      enableReinitialize
    >
      {({handleChange, handleBlur, handleSubmit, errors, values, resetForm, setFieldValue}) => (
        <form id="general-settings-form" onSubmit={handleSubmit} noValidate>
          <div className={styles.generalInfo}>
            <div className={styles.header}>
              <Typography classes={styles.heading} variant="h2">
                {t('General Information')}
              </Typography>
              <div className={styles.actions}>
                {isEditing ? (
                  <>
                    <Button
                      size="small"
                      onClick={() => {
                        onEditCancel();
                        resetForm();
                      }}
                      disabled={isSaving}
                    >
                      {t('Cancel')}
                    </Button>
                    <Button
                      size="small"
                      type="submit"
                      form="general-settings-form"
                      variant="contained"
                      disabled={isSaving || !!Object.values(errors).filter(Boolean).length}
                      startIcon={
                        isSaving ? <CircularLoader size="1em" color="inherit" /> : undefined
                      }
                    >
                      {t('Save Changes')}
                    </Button>
                  </>
                ) : (
                  <BorderButton size="small" onClick={onEdit}>
                    {t('Edit', {ns: 'translation'})}
                  </BorderButton>
                )}
              </div>
            </div>
            <div className={styles.projectInfo}>
              <div className={styles.label}>{t('Project Name')}:</div>
              <TextField
                value={values.name}
                size="small"
                className={classNames(styles.name, {[styles.name__readOnly]: !isEditing})}
                onChange={handleChange}
                onBlur={handleBlur}
                name="name"
                error={!!errors.name}
                helperText={errors.name}
                InputProps={{readOnly: !isEditing}}
                required
              />

              <div className={styles.label}>{t('Project Location')}:</div>
              <div>
                <TextField
                  value={values.locationName}
                  size="small"
                  className={classNames(styles.name, {[styles.name__readOnly]: !isEditing})}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="locationName"
                  error={!!errors.locationName}
                  helperText={errors.locationName}
                  InputProps={{readOnly: !isEditing}}
                  required
                />
                <div className={styles.latlang}>
                  {t('Lattitude', {ns: 'settings'})} : {roundNumber(values.location.lat, 5)},{' '}
                  {t('Longitude', {ns: 'settings'})} : {roundNumber(values.location.lng, 6)}
                  {isEditing && (
                    <BorderButton
                      className={styles.chooseMap}
                      onClick={showMapPicker}
                      size="small"
                      startIcon={<AddLocationIcon />}
                    >
                      {t('Choose', {ns: 'settings'})}
                    </BorderButton>
                  )}
                </div>
              </div>

              <div className={styles.label}>{t('Time Zone')}:</div>
              <TimeZoneSelect
                onChange={handleChange}
                onBlur={handleBlur}
                className={classNames(styles.timezone, {
                  [styles.timezone__readOnly]: !isEditing
                })}
                readOnly={!isEditing}
                name="timezone"
                value={values.timezone}
              />

              <div className={styles.label}>{t('Created')}:</div>
              <div className={styles.value}>
                {project.createdOn
                  ? formatInTimeZone(project.createdOn, timezone, DATE_FORMATS.DATE_TIME)
                  : undefined}
              </div>

              <div className={styles.label}>{t('Start Date')}:</div>
              <TextField
                className={classNames(styles.name, {[styles.name__readOnly]: !isEditing})}
                onClick={isEditing ? showPicker : undefined}
                autoComplete="off"
                name="date"
                placeholder={isEditing ? t('Select Date') : undefined}
                size="small"
                InputProps={{
                  className: styles.dateInput,
                  endAdornment: null,
                  readOnly: !isEditing
                }}
                value={
                  values.startedOn
                    ? formatInTimeZone(values.startedOn, timezone, DATE_FORMATS.DATE)
                    : ''
                }
              />
              <div className={styles.label}>{t('Total Processed Photos')}:</div>
              <div className={styles.value}>
                {projectImageStatistics?.totalProcessedImages
                  ? projectImageStatistics?.totalProcessedImages
                  : t('No photos received')}
              </div>
            </div>
          </div>
          {isPickerOpen && (
            <DialogContainer open className={styles.dialog} onClose={hidePicker}>
              <DialogContent className={styles.content}>
                <DateCalendar
                  className={styles.calendar}
                  onChange={date => {
                    setFieldValue('startedOn', date);
                    hidePicker();
                  }}
                  timezone={timezone}
                  maxDate={new Date()}
                  value={values.startedOn}
                />
              </DialogContent>
            </DialogContainer>
          )}
          {isMapPickerOpen && (
            <LocationPickerDialog
              onClose={hideMapPicker}
              onChange={location => {
                setFieldValue('location', location);
              }}
              center={values.location}
              value={values.location}
            />
          )}
        </form>
      )}
    </Formik>
  );
}

function getNameError(name: string, t: TFunction) {
  if (!name) {
    return t('Project name is required');
  }

  if (name.length > 100) {
    return t('Project name should be less than {{maxLen}} chars', {maxLen: 100});
  }

  return undefined;
}

function getLocationError(locationName: string, t: TFunction) {
  if (!locationName) {
    return t('Location name is required');
  }

  if (locationName.length > 100) {
    return t('Location name should be less than {{maxLen}} chars', {maxLen: 100});
  }

  return undefined;
}

function getValidate(t: TFunction) {
  return function (values: FormValues) {
    const errors: FormErrors = {};
    const {name, locationName} = values;

    const nameError = getNameError(name, t);
    if (nameError) {
      errors.name = nameError;
    }

    const locationError = getLocationError(locationName, t);
    if (locationError) {
      errors.locationName = locationError;
    }
    return errors;
  };
}
