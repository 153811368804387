import {useEffect} from 'react';
import {useQuery} from '@tanstack/react-query';
import {ToastService} from '@shared/lib/services';
import {useCurrentProject} from '@entities/project';
import {useTranslation} from 'react-i18next';
import {ProductionTrackingService} from '../services/ProductionTracking.service';

export function useLogs() {
  const {
    project: {id: projectId}
  } = useCurrentProject();
  const {t} = useTranslation('productionTracking');
  const {data, isLoading, isError, error} = useQuery({
    queryKey: ['production-tracking-logs', projectId],
    queryFn: () => ProductionTrackingService.getLogs(projectId)
  });

  const errorMessage = isError && error.message;
  useEffect(() => {
    if (errorMessage) {
      ToastService.error(t('Failed to fetch logs information'));
    }
  }, [errorMessage, t]);

  return {
    data: data || [],
    isFetching: isLoading,
    isError
  };
}
