import {useTranslation} from 'react-i18next';
import {useMutation} from '@shared/lib/hooks';
import {ProjectService, useCurrentProject} from '@entities/project';
import {fileToBase64} from '@conxai/react-kit';
import {HTTPError} from 'ky';

export function useCameraImageIngest() {
  const {t} = useTranslation(['project', 'settings']);
  const {
    project: {id: projectId}
  } = useCurrentProject();
  const {
    mutate: ingestImage,
    isLoading: isSaving,
    isSuccess: isSaved
  } = useMutation<{image: File; cameraId: string}>({
    mutationFn: ({cameraId, image}) => {
      return fileToBase64(image).then(base64Image => {
        return ProjectService.ingestCameraImage(projectId, cameraId, base64Image).catch(err => {
          if (err instanceof HTTPError) {
            if (err.response.status === 400) {
              throw new Error(t('File is not supported', {ns: 'settings'}));
            }
          }
          throw new Error(t('Error occurred. Please try to create again.'));
        });
      });
    },
    options: {skipInvalidation: true}
  });

  return {
    ingestImage,
    isSaving,
    isSaved: isSaved
  };
}
