import {useMemo} from 'react';
import {TimeRange} from '@shared/ui/TimeRange';
import {ComplianceInfo} from '@entities/ppeDashboard';
import {AxisChart, AxisChartOptions} from '@shared-app/ui';
import {useTranslation} from 'react-i18next';
import {getHourlyNonComplianceByDate} from '@entities/ppeDashboard';
import {NON_COMPLIANCE_HEATMAP, useDateLocale} from '@shared-app/lib';
import {formatInTimeZone} from 'date-fns-tz';
import {useProjectTimezone} from '@entities/project';
import {DATE_FORMATS} from '@shared/lib/constants';
import styles from './NonComplianceFrequencyByTimeHeatmap.module.scss';

interface Props {
  complianceInfo: ComplianceInfo[];
  timeRange: TimeRange;
  isRelative: boolean;
}

export function NonComplianceFrequencyByTimeHeatmap({
  complianceInfo,
  isRelative,
  timeRange
}: Props) {
  const {t} = useTranslation('ppeDashboard');
  const locale = useDateLocale();
  const timezone = useProjectTimezone();

  const series = useMemo(
    () => getHourlyNonComplianceByDate(complianceInfo, timeRange, isRelative),
    [complianceInfo, timeRange, isRelative]
  );

  if (complianceInfo.length >= 21) {
    return (
      <div className={styles.chooseLessDays}>
        {t('Please select a period of less than 3 weeks to view this chart')}
      </div>
    );
  }

  return (
    <AxisChart
      title={t('PPE Non-Compliance Frequency by Time')}
      series={series.map(({name, data}) => ({name, data: data.map(([, value]) => value)}))}
      options={getOptions(timezone, series.at(0)?.data, locale)}
      type="heatmap"
    />
  );
}

function getOptions(
  timezone: string,
  series: [number, number][],
  locale: Locale
): AxisChartOptions {
  return {
    plotOptions: {
      heatmap: {
        radius: 0
      }
    },
    colors: NON_COMPLIANCE_HEATMAP.COLORS,
    xaxis: {
      labels: {
        offsetX: 0,
        rotateAlways: true,
        minHeight: 88,
        formatter: function (v: unknown, t: number, opts?: {i: number}) {
          if (series[opts?.i] === undefined) {
            return '';
          }
          const timestamp = series[opts?.i][0];
          // `overwriteCategories` will cause `timestamp` to be string
          return typeof timestamp === 'string'
            ? timestamp
            : formatInTimeZone(timestamp as number, timezone, DATE_FORMATS.DATE_ABBR_MONTH, {
                locale
              });
        }
      },
      categories: series.map(([date]) => date),
      tickPlacement: 'on',
      type: 'category' //https://github.com/apexcharts/apexcharts.js/issues/4036
    }
  };
}
