import {Outlet} from 'react-router-dom';
import Header from './Header/Header';
import styles from './Layout.module.scss';
import {ProtectedLayoutWrapper} from '@entities/auth';
import {Footer} from '@shared-app/ui/Footer/Footer';

export default function Layout() {
  return (
    <ProtectedLayoutWrapper>
      <div className={styles.container}>
        <Header />
        <div className={styles.columns}>
          <div className={styles.content}>
            <Outlet />
          </div>
        </div>
        <Footer timezone="UTC" />
      </div>
    </ProtectedLayoutWrapper>
  );
}
