import {useRef} from 'react';
import {DialogContainer, DialogContent} from '@shared/ui/Dialog';
import {BorderButton} from '@shared/ui/Button';
import {CloseIcon} from '@shared/ui/icons';
import {LatLng} from '@shared-app/types';
import {MapContainer} from 'react-leaflet';
import {Map} from 'leaflet';
import {OpenStreetMapMarker} from './OpenStreetMapMarker';
import styles from './LocationPickerDialog.module.scss';

interface LocationPickerDialogProps {
  onClose: () => void;
  onChange: (v: LatLng) => void;
  value: LatLng;
  center: LatLng;
}

export function OpenStreetMapPickerDialog({
  onClose,
  onChange,
  value,
  center
}: LocationPickerDialogProps) {
  const mapRef = useRef<Map>();
  return (
    <DialogContainer
      open
      className={styles.mapDialog}
      onClose={onClose}
      PaperProps={{className: styles.mapDialogPaper}}
    >
      <DialogContent className={styles.mapDialogContent}>
        <BorderButton className={styles.closeButton} onClick={onClose}>
          <CloseIcon />
        </BorderButton>
        <MapContainer className={styles.map} ref={mapRef} center={center} zoom={13}>
          <OpenStreetMapMarker onChange={onChange} value={value} />
        </MapContainer>
      </DialogContent>
    </DialogContainer>
  );
}
