import {useState} from 'react';
import {Tab, TabPanel, Tabs} from '@shared/ui/Tabs';
import {useTranslation} from 'react-i18next';
import {ProductionTrackingHome} from '@modules/productionTracking/widgets/home';
import {HistoricalGraphs} from '@modules/productionTracking/widgets/historicalGraphs';
import styles from './ProductionTracking.module.scss';
import {usePostProcessInfo} from '@modules/productionTracking/entities';
import {ProductionTrackingSkeleton} from './ProductionTrackingSkeleton';
import {ProductionTrackingLogs} from '@modules/productionTracking/widgets/logs';

enum tabs {
  home,
  graphs,
  logs
}

export function ProductionTrackingPage() {
  const [selectedTab, setSelectedTab] = useState(0);
  const {data, isFetching} = usePostProcessInfo();
  const {t} = useTranslation('productionTracking');

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  if (isFetching) {
    return <ProductionTrackingSkeleton />;
  }

  if (!data) {
    return t('No data', {ns: 'translation'});
  }

  return (
    <div className={styles.root}>
      <div>
        <Tabs onChange={handleChange} value={selectedTab}>
          <Tab className={styles.tab} label={t('Home')}></Tab>
          <Tab className={styles.tab} label={t('Graphs')}></Tab>
          <Tab className={styles.tab} label={t('Logs')}></Tab>
        </Tabs>
      </div>
      <div className={styles.tabContent}>
        <TabPanel className={styles.tabPanel} selectedIndex={selectedTab} index={tabs.home}>
          <ProductionTrackingHome />
        </TabPanel>
        <TabPanel className={styles.tabPanel} selectedIndex={selectedTab} index={tabs.graphs}>
          <HistoricalGraphs dateRange={data.dateRange} />
        </TabPanel>
        <TabPanel className={styles.tabPanel} selectedIndex={selectedTab} index={tabs.logs}>
          <ProductionTrackingLogs />
        </TabPanel>
      </div>
    </div>
  );
}
