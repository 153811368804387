import {CircularLoader} from '@shared/ui/Loader';
import {MenuItem} from '@shared/ui/Menu';
import {Select, SelectChangeEvent, SelectProps} from '@shared/ui/Select';
import {useEffect, useState} from 'react';

export interface HourSelectProps extends Omit<SelectProps, 'onChange'> {
  onChange?: (event: SelectChangeEvent<string>) => void;
  variant?: 'filled' | 'outlined' | 'standard' | 'rounded';
  value: string;
}

export function TimeZoneSelect({onChange, value, ...restProps}: HourSelectProps) {
  const [timezones, setTimezones] = useState<string[]>([]);

  useEffect(() => {
    import('@shared/lib/constants/timezones').then(module => {
      setTimezones(module.timezones);
    });
  }, []);

  if (timezones.length === 0) {
    return <CircularLoader size={'1em'} />;
  }

  return (
    <Select
      onChange={onChange}
      variant="outlined"
      value={value}
      size="small"
      displayEmpty
      fullWidth
      {...restProps}
    >
      {timezones.map(timezoneIdentifier => (
        <MenuItem key={timezoneIdentifier} value={timezoneIdentifier}>
          {timezoneIdentifier}
        </MenuItem>
      ))}
    </Select>
  );
}
