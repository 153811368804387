import {useCallback} from 'react';
import {useUpdateProject} from './useUpdateProject';
import {Project} from '@shared/types/Project';
import {instanceToInstance} from 'class-transformer';
import {LatLng} from '@shared-app/types';

export function useSaveProjectInfo(project: Project) {
  const {update, isUpdated, isUpdating} = useUpdateProject(updateProjectInfoCache);

  const updateInfo = useCallback(
    (info: {
      name: string;
      locationName: string;
      timezone: string;
      startedOn: Date;
      location: LatLng;
    }) => {
      const updatedProject = instanceToInstance(project, {ignoreDecorators: true});
      updatedProject.name = info.name;
      updatedProject.settings.location.name = info.locationName;
      updatedProject.settings.location.timezone = info.timezone;
      updatedProject.settings.startedOn = info.startedOn;
      updatedProject.settings.location.lat = info.location.lat;
      updatedProject.settings.location.long = info.location.lng;
      update(updatedProject);
    },
    [update, project]
  );

  return {updateInfo, isSaved: isUpdated, isSaving: isUpdating};
}

function updateProjectInfoCache(prevProject: Project, nextProject: Project) {
  const updatedProject = instanceToInstance(prevProject, {ignoreDecorators: true});
  updatedProject.name = nextProject.name;
  updatedProject.settings.location = nextProject.settings.location;
  updatedProject.settings.startedOn = nextProject.settings.startedOn;
  return updatedProject;
}
