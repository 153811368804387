import {useRef} from 'react';
import {Thread} from '@assistant-ui/react';
import {useLangGraphRuntime} from '@assistant-ui/react-langgraph';
import {makeMarkdownText} from '@assistant-ui/react-markdown';

import {createThread, getThreadState, sendMessage} from '../lib/utils/chatApi';
import {Project} from '@shared/types/Project';

const MarkdownText = makeMarkdownText();

export function MyAssistant({project}: {project: Project}) {
  const threadIdRef = useRef<string | undefined>();
  const runtime = useLangGraphRuntime({
    threadId: threadIdRef.current,
    stream: async messages => {
      if (!threadIdRef.current) {
        const {thread_id} = await createThread();
        threadIdRef.current = thread_id;
      }
      const threadId = threadIdRef.current;

      return sendMessage({
        threadId,
        messages,
        projectId: project.id,
        timezone: project.settings.location.timezone
      });
    },
    onSwitchToNewThread: async () => {
      const {thread_id} = await createThread();
      threadIdRef.current = thread_id;
    },
    onSwitchToThread: async threadId => {
      const state = await getThreadState(threadId);
      threadIdRef.current = threadId;
      return {messages: state.values.messages};
    }
  });

  return <Thread runtime={runtime} assistantMessage={{components: {Text: MarkdownText}}} />;
}
