import {Event} from '@entities/occupancy';
import {roundDateRangeToNearHour} from '@shared-app/lib';
import {ONE_HOUR_IN_MS} from '@shared/lib/constants';
import {maxBy, rangeDates} from '@shared/lib/utils';
import {DateRange} from '@shared/ui/Date';
import {addHours} from 'date-fns';

type SeriesItem = [number, number];

function getOccupancyOverTimeSeriesLessthanDay(events: Event[]): SeriesItem[] {
  return events.map(event => [event.timestamp.getTime(), event.count]);
}

function getEventsWithinDateRange(events: Event[], startDate: Date, endDate: Date) {
  return events.filter(({timestamp}) => timestamp >= startDate && timestamp < endDate);
}

function getLastNonEmptyValue(series: [number, number][], max: number) {
  return series.filter((serieItem, index) => serieItem[1] !== undefined && index < max).at(-1)[1];
}

export function getOccupancyOverTimeSeriesMorethanDay(
  events: Event[],
  timezone: string
): SeriesItem[] {
  const dateRangeRounded = roundDateRangeToNearHour(
    {startDate: events.at(0).timestamp, endDate: events.at(-1).timestamp},
    timezone
  );
  const seriesTimestamps = rangeDates(
    dateRangeRounded.startDate,
    dateRangeRounded.endDate,
    ONE_HOUR_IN_MS
  );

  const data = seriesTimestamps.map<[number, number]>((startTimestamp, index) => {
    const endTimestamp = addHours(startTimestamp, 1);
    const eventsInRange = getEventsWithinDateRange(events, startTimestamp, endTimestamp);

    if (eventsInRange.length === 0 && index > 0) {
      return [startTimestamp.getTime(), undefined]; // `undefined` represents continuity withouting dropping to zero
    }

    const maxCount = maxBy(eventsInRange, event => event.count);

    return [startTimestamp.getTime(), maxCount];
  });

  return data.map((value, index, all) =>
    value[1] === undefined ? [value[0], getLastNonEmptyValue(all, index)] : value
  );
}

export function getOccupancyOverTimeSeries(
  events: Event[],
  dateRange: DateRange,
  timezone: string,
  shouldAggregate: boolean
): [number, number][] {
  const dateRangeRounded = roundDateRangeToNearHour(dateRange, timezone);

  const filteredEvents = shouldAggregate ? events.filter(event => event.type !== 0) : events;

  if (filteredEvents.length === 0) {
    return [];
  }

  const actualDataPoints = shouldAggregate
    ? getOccupancyOverTimeSeriesMorethanDay(filteredEvents, timezone)
    : getOccupancyOverTimeSeriesLessthanDay(filteredEvents);
  const forecastedFirstDataPoint = [
    dateRangeRounded.startDate.getTime(),
    filteredEvents[0].type === 0
      ? filteredEvents[0].count
      : filteredEvents[0].type === 1
        ? filteredEvents[0].count - 1
        : filteredEvents[0].count + 1
  ] satisfies SeriesItem;
  const forecastedLastDataPoint = [
    dateRangeRounded.endDate.getTime(),
    actualDataPoints.at(-1)[1]
  ] satisfies SeriesItem;

  return [forecastedFirstDataPoint, ...actualDataPoints, forecastedLastDataPoint];
}
